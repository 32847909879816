import orgService from '../../../../services/notisphere/organization'
export default {
    data() {
        return {
            item: {
                id: null,
                name: null,
                createdBy: null,
                linkedSupplierId: null
            },
            autocompleteItemslst: [],
            autocompleteItems: [],
            formType: 'INSERT',
            selectedId: null,
            selectedName: null,
            linkedSupplierId: null
        }
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'Add New Supplier',
                    showAdminCreation: true,
                    showStatusSwitcher: false
                }
            }
            if(this.formType == 'LINKED'){
                return {
                    title: 'Link Supplier: ' + this.selectedName,
                    showAdminCreation: true,
                    showStatusSwitcher: false
                }
            }
        },
    },
    methods: {
        forceRerenderSupplierList(payload,data,linkedSupplierName) {
            for (let i = 0; i < data.length; i++) {
                this.autocompleteItems.push({text: data[i].text,value: data[i].id})
                this.autocompleteItemslst.push(data[i].text)
            }
            this.formType = 'LINKED'
            this.item.linkedSupplierId=linkedSupplierName
            this.selectedId=payload.id
            this.selectedName= payload.supplier_name
            this.linkedSupplierId = payload.linked_supplier_id
            this.$refs.modal.show()

        },
        save() {

            var isValid = this.$refs['observer'].validate()
            if(isValid && this.formType == 'LINKED'){
                var findlinksupplierid=this.autocompleteItems.filter(s=>s.text==this.item.linkedSupplierId)[0]?.value

                if(findlinksupplierid== undefined)
                {
                    this.item.linkedSupplierId=''
                    this._showToast('Supplier name not present', {title: 'Error' , variant: 'danger'})
                    this.$refs.modal.hide()
                    this.$refs.modal.show()
                }
                else{
                    this.linkSupplier()
                }

            }
        },
        linkSupplier() {
            var findlinksupplierid=this.autocompleteItems.filter(s=>s.text==this.item.linkedSupplierId)[0]?.value
            orgService.linkSupplierName(this.selectedId,findlinksupplierid).then(resp=>{
                if (!resp.error) {
                    this.$emit('onCompleted', 'LINKED')
                    this.$refs.modal.hide()
                }

            })
        }
    }
}